import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@/store/index';

import BlogModel from '@/views/blog/model/BlogModel'

export default class BlogViewModel {
  constructor() {
    this.model = new BlogModel();
    this.isMobile = false;
    this.searchParams = {
      nextId:-1,
      limit:3,
    };
  }
  init(){
    this.getNoticeList();
    this.getPostList();
  }
  getNoticeList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.NOTICE_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.setNoticeRecent(resultData.data);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getPostList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.POST_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.setPostRecent(resultData.data);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}