<template>
  <SectionBasic
    id="post"
    :isActive="isActive">
    <template
      v-if="viewModel.isMobile">
      <h3 class="tit_board">포스트</h3>
      <template
        v-if="viewModel.model.postRecentDataList.length > 1">
        <swiper
          class="swiper_post"
          :options="swiperPostOption">
          <swiper-slide v-for="(postItem,index) in viewModel.model.postRecentDataList" :key="'postSwiper'+index">
            <BoardItem
              :itemData="postItem"
              itemRouteName="PostDetail" />
          </swiper-slide>
          <div class="swiper-pagination swiper-post-pagination" slot="pagination"></div>
        </swiper>
      </template>
      <template
        v-else>
        <BoardItem
          class="item_board_only_one"
          :itemData="viewModel.model.postRecentDataList[0]"
          itemRouteName="PostDetail" />
      </template>
      <router-link :to="$routerPath.POST_LIST" class="btn_board">전체보기</router-link>
    </template>
    <BoardList
      v-else
      title="포스트"
      :dataList="viewModel.model.postRecentDataList"
      btnText="전체보기"
      routeName="PostList"
      itemRouteName="PostDetail"/>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';
import BoardList from '@/components/common/board/BoardList';
import BoardItem from '@/components/common/board/BoardItem';
import { Swiper, SwiperSlide, Pagination } from 'vue-awesome-swiper'

export default {
  name:'SectionPost',
  props:{
    viewModel: Object,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
    BoardList,
    BoardItem,
    Swiper,
    SwiperSlide,
    Pagination,
  },
  data(){
    return{
      swiperPostOption: {
        // autoplay: {
        //   delay: 3000
        // },
        // loop: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 40,
        centeredSlides: true,
        speed:100,
        mousewheel:{
          forceToAxis:true,
        },
        pagination: {
          el: '.swiper-post-pagination',
          type: 'bullets',
          clickable: true
        },
      },
    }
  }
}
</script>

<style scoped>
.section_post{padding:48px 0 120px;background-color:#F1EEE1}

/* 모바일 */
@media all and (max-width:880px){
  .section_post{padding:96px 0 48px}
}
</style>