<template>
  <PageWithLayout>
    <h2 class="screen_out">Blog</h2>
    <SectionNotice
      :isActive="sectionsTop.notice.show"
      :viewModel="viewModel" />
    <SectionPost
      :isActive="sectionsTop.post.show"
      :viewModel="viewModel" />
    <SectionLetter
      :isActive="sectionsTop.letter.show" />
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
// components
import SectionNotice from '@/views/blog/view/components/notice/SectionNotice';
import SectionPost from '@/views/blog/view/components/post/SectionPost';
import SectionLetter from '@/views/blog/view/components/letter/SectionLetter';

// viewModel
import BlogViewModel from '@/views/blog/viewModel/BlogViewModel';
// mixins
import showSection from '@/mixins/ShowSection'

export default {
  name:'Blog',
  mixins:[showSection],
  components:{
    PageWithLayout,
    SectionNotice,
    SectionPost,
    SectionLetter,
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      breakPoint:880,
      viewModel: new BlogViewModel(),
      sectionsTop:{
        notice: { id:'notice',top:0,show:true },
        post: { id:'post',top:null,show:true },
        letter: { id:'letter',top:null,show:false },
      },
    }
  },
  metaInfo: {
    meta: [
      {
        property: "description",
        content: "우리 가족의 슬기로운 금융생활을 위한 퍼핀 블로그와 퍼핀레터. 실생활에 직접 적용할 수 있는 금융, 경제, 교육 이야기를 전해드려요.",
        vmid:"description",
      },
      {
        property: "og:description",
        content: "우리 가족의 슬기로운 금융생활을 위한 퍼핀 블로그와 퍼핀레터. 실생활에 직접 적용할 수 있는 금융, 경제, 교육 이야기를 전해드려요.",
        vmid:"og:description",
      },
      {
        property: "twitter:description",
        content: "우리 가족의 슬기로운 금융생활을 위한 퍼핀 블로그와 퍼핀레터. 실생활에 직접 적용할 수 있는 금융, 경제, 교육 이야기를 전해드려요.",
        vmid:"twitter:description",
      },
    ],
  },
}
</script>
<style scoped>

/* 모바일 */
@media all and (max-width:880px){
  .section_comm >>> .tit_board{display:block;font-weight:700;font-size:28px;line-height:42px;color:#111}
  .section_comm >>> .btn_board{display:block;width:180px;margin:48px auto 0;padding:14px 0;border-radius:4px;font-weight:700;font-size:18px;line-height:28px;background-color:#BEA9ED;text-align:center}

  .section_comm >>> .swiper-container{overflow:visible;margin-top:48px;padding-bottom:42px}
  .section_comm >>> .swiper-container  .swiper-wrapper{z-index:20}
  .section_comm >>> .swiper-container  .swiper-slide{height:auto;text-align:center}
  .section_comm >>> .item_board{display:inline-block;position:relative;width:100%}
  .section_comm >>> .swiper-pagination{height:10px;bottom:0}
  .section_comm >>> .swiper-pagination  .swiper-pagination-bullet{width:10px;height:10px;margin:0 3px;opacity:1;background-color:#D8CBF4;vertical-align:top}
  .section_comm >>> .swiper-pagination  .swiper-pagination-bullet-active{background-color:#8F69DD}

  .section_comm >>> .item_board_only_one{display:block;margin:48px auto 0}
  /* letter */
}
</style>