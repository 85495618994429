export default class BlogModel {
  constructor(){
    this.noticeRecentDataList = [];
    this.postRecentDataList = []
  }
  setNoticeRecent(array){
    this.noticeRecentDataList = array;
  }
  setPostRecent(array){
    this.postRecentDataList = array;
  }
}