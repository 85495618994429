<template>
  <SectionBasic
    id="letter"
    :isActive="isActive">
    <div class="group_half">
      <div class="half_right area_middle">
        <div class="inner_middle">
          <h3 class="tit_letter">우리 가족의 슬기로운 금융<br>생활을 위한 퍼핀레터</h3>
          <p class="desc_letter">실생활에 직접 적용할 수 있는 금융, 경제, 교육 이야기를 <br>정기 메일로 발송해 드립니다.</p>
          <div class="area_img"></div>
          <div class="group_btn">
            <a class="btn_letter btn_subscribe" href="https://page.stibee.com/subscriptions/162751?groupIds=144911" target="_blank">무료 구독하기</a>
            <a class="btn_letter btn_last" href="https://firfin.stibee.com/" target="_blank">지난 레터 엿보기</a>
          </div>
        </div>
      </div>
      <div class="half_left">
        <div class="area_img"></div>
      </div>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name:'SectionLetter',
  props:{
    isActive: Boolean,
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_letter{padding:120px 0 100px;background-color:#fff}

.section_letter .half_left{padding:0 20px 20px 0}
.section_letter .area_img{width:100%;height:100%;background:#f3f1f1 url(/assets/images/blog/img_blog_letter.png) no-repeat center;background-size:auto 100%}

.section_letter .half_right.area_middle{padding:0 0 20px 60px}
.section_letter .half_right .tit_letter{display:block;font-weight:700;font-size:48px;line-height:64px;color:#111}
.section_letter .half_right .desc_letter{margin-top:24px;font-weight:500;font-size:20px;line-height:30px;color:#333}
.section_letter .half_right .area_img{display:none}
.section_letter .half_right .group_btn{display:flex;flex-wrap:nowrap;overflow:hidden;margin-top:24px;justify-content:center}
.section_letter .group_btn .btn_letter{display:inline-block;width:180px;min-height:56px;padding:14px;border-radius:4px;font-weight:700;font-size:18px;line-height:28px;color:#000;box-sizing:border-box;text-align:center;vertical-align:top}
.section_letter .group_btn .btn_letter + .btn_letter{margin-left:24px}
.section_letter .group_btn .btn_subscribe{background-color:#BEA9ED}
.section_letter .group_btn .btn_last{background-color:#F3F1F1}

/* 모바일 */
@media all and (max-width:1199px){
  .section_letter{position:relative;padding:48px 0}
  .section_letter .half_left{display:none}
  .section_letter .half_right.area_middle{padding:0}
  .section_letter .half_right .inner_middle{display:block}
  .section_letter .half_right .tit_letter{font-size:30px;line-height:45px;word-wrap:break-word;word-break:keep-all}
  .section_letter .half_right .tit_letter br{display:none}
  .section_letter .half_right .desc_letter{margin-top:32px;font-weight:700;font-size:18px;line-height:27px;color:#666;word-wrap:break-word;word-break:keep-all}
  .section_letter .half_right .desc_letter br{display:none}
  .section_letter .half_right .area_img{display:block;position:static;height:320px;margin-top:32px}
  .section_letter .half_right .group_btn{display:block;margin-top:32px;text-align:center}
  .section_letter .group_btn .btn_letter{display:block;width:100%;max-width:350px;margin:0 auto}
  .section_letter .group_btn .btn_letter + .btn_letter{margin:16px auto 0}
}
</style>