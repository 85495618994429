<template>
  <SectionBasic
    id="notice"
    :isActive="isActive">
    <template
      v-if="viewModel.isMobile">
      <h3 class="tit_board">공지&amp;이벤트</h3>
      <template
        v-if="viewModel.model.noticeRecentDataList.length > 1">
        <swiper
          class="swiper_notice"
          :options="swiperNoticeOption">
          <swiper-slide v-for="(noticeItem,index) in viewModel.model.noticeRecentDataList" :key="'noticeSwiper'+index">
            <BoardItem
              :itemData="noticeItem"
              itemRouteName="NoticeDetail" />
          </swiper-slide>
          <div class="swiper-pagination swiper-notice-pagination" slot="pagination"></div>
        </swiper>
      </template>
      <template
        v-else>
        <BoardItem
          class="item_board_only_one"
          :itemData="viewModel.model.noticeRecentDataList[0]"
          itemRouteName="NoticeDetail" />
      </template>
      <router-link :to="$routerPath.NOTICE_LIST" class="btn_board">전체보기</router-link>
    </template>
    <BoardList
      v-else
      title="공지&amp;이벤트"
      :dataList="viewModel.model.noticeRecentDataList"
      btnText="전체보기"
      routeName="NoticeList"
      itemRouteName="NoticeDetail"/>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';
import BoardList from '@/components/common/board/BoardList';
import BoardItem from '@/components/common/board/BoardItem';
import { Swiper, SwiperSlide, Pagination } from 'vue-awesome-swiper'

export default {
  name:'SectionNotice',
  props:{
    viewModel: Object,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
    BoardList,
    BoardItem,
    Swiper,
    SwiperSlide,
    Pagination,
  },
  data(){
    return{
      swiperNoticeOption: {
        // autoplay: {
        //   delay: 3000
        // },
        // loop: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 40,
        centeredSlides: true,
        speed:100,
        mousewheel:{
          forceToAxis:true,
        },
        pagination: {
          el: '.swiper-notice-pagination',
          type: 'bullets',
          clickable: true
        },
      },
    }
  }
}
</script>

<style scoped>
.section_notice{padding:160px 0 0;background-color:#F1EEE1}

/* 모바일 */
@media all and (max-width:1199px){
  .section_notice{padding:102px 0 0}
}
</style>